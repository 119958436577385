.lisa {
    width: 300px;
    border: 5px solid whitesmoke;
    margin-bottom: 20px;
}

.kontakt {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: whitesmoke;
}