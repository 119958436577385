.header {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;

}

a {
    color: whitesmoke;
    text-decoration: none;
}

a:hover {
    color: rgb(94, 92, 197);
  }

.headerLink {
    padding: 20px;
    margin-bottom: 20px;
    margin-left: 100px;
    margin-right: 100px;
    font-size: 20px;
    border-radius: 15px;
}



/** kleine Monitore **/
.logo {  
    height: 90px; 
    box-sizing: border-box; 
    text-align: right;
}

/** Größere Monitore **/
@media only screen and (min-width: 600px) {
    .logo { 
        height: 200px; 
    }
}

