.image {
  height: 200px;
  padding: 10px;
  cursor: pointer;
}

.photoPage {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.linkContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 80%;
}

.linkColumn {
  flex: 50%;
  margin-bottom: 20px;
}

.linkPanel {
  width: 300px;
}

@media only screen and (min-width: 600px) {
  .linkPanel {
    width: 500px;
  }
}
